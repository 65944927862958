<template>
  
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn  v-if="isNew"
          rounded
          v-on="on"
          v-bind="attrs"
          color="green"
          depressed
          class="white--text"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add Tax Type") }}
        </v-btn>
        <v-icon v-if="!isNew" v-on="on" v-bind="attrs" color="blue" @click="fetchData">mdi-pen</v-icon>
       
       
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ isNew ? $t("Add Tax Type") : $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field
                outlined
                dense
                :label="$t('name')"
                v-model="structure.name"
              ></v-text-field>
            </v-col>
            <v-col md="6">
                <v-text-field
                outlined
                dense
                :label="$t('Code')"
                v-model="structure.code"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            depressed
            class="white--text"
            @click="submit"
            :loading="btnLoading"
            >{{ $t("storage") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      dialog: false,

      structure: {
        code: null,
        name: null,
 
      },
    };
  },
  props: {
    
    isNew: {
            type: Boolean,
            default: true,
        },
      item: {
        type: Object,
        default: () => {
                return {};
            },
      }  
  },
  computed: {
 
  },
  methods: {
    
 
    async submit() {
      try {
        this.btnLoading = true;
        if(this.isNew) {
          await axios.post("/accounting/tax-type", this.structure);
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        } else{
          await axios.put(`/accounting/tax-type/${this.item.id}`, this.structure);
          this.$Notifications(
            this.$t('edit success'),
            { rtl: true, timeout: 2000 },
            "success"
          );
        }
        
        this.$emit("Submitted");
       this.clearData();
      } catch (err) {
      } finally {
        this.btnLoading = false;
      
      }
    },
    clearData(){
      this.structure.name = null;
      this.structure.code = null;
      this.dialog = false;
    },
   async fetchData(){
   let res = await axios.get(`/accounting/tax-type/${this.item.id}`);
   let data = res.data.data;
   this.structure.name = data.name;
    this.structure.code = data.code;
    },
  },
  created() {
   
  },
};
</script>

<style>
</style>